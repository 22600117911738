import { el } from 'redom'
import CCCampProjection from './projection'
import { IControl } from 'maplibre-gl'

class GridControl implements IControl {
    onAdd(map: maplibregl.Map) {
        const control = el('div', {
            class: 'maplibregl-ctrl grid-control',
        })

        map.on('mousemove', (e) => {
            const proj = new CCCampProjection()
            const pos = proj.convertToGrid(e.lngLat)
            if (pos) {
                control.innerHTML = `Cursor position: <b>${pos}</b>`
                control.style.display = 'block'
            } else {
                control.style.display = 'none'
            }
        })
        return control
    }

    onRemove() {}
}

export { GridControl as default }
