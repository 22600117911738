import { DataDrivenPropertyValueSpecification, FormattedSpecification } from 'maplibre-gl'
import { LayerSpecificationWithZIndex } from './types'

const fiber_label: DataDrivenPropertyValueSpecification<FormattedSpecification> = [
    'case',
    ['has', 'fiber'],
    ['concat', 'fiber ', ['get', 'fiber'], ' (', ['get', 'cores'], 'cores)'],
    ['has', 'cores'],
    ['concat', 'fiber (', ['get', 'cores'], ['case', ['==', ['get', 'cores'], '1'], ' core)', ' cores)']],
    'fiber',
]

const style: Array<LayerSpecificationWithZIndex> = [
    {
        id: 'noc_fibers',
        type: 'line',
        source: 'site_plan',
        zindex: 101,
        minzoom: 16,
        'source-layer': 'c23_noc_2c_fiber_linestring',
        paint: {
            'line-color': 'rgba(208, 189, 5, 1)',
            'line-width': ['interpolate', ['linear'], ['zoom'], 16, 1, 20, 3],
        },
    },
    {
        id: 'nocprivate_uplink',
        type: 'line',
        source: 'site_plan',
        minzoom: 14,
        zindex: 101,
        'source-layer': 'c23_noc_uplink_linestring',
        paint: {
            'line-color': 'rgba(208, 189, 5, 1)',
            'line-width': ['interpolate', ['linear'], ['zoom'], 14, 1, 20, 5],
        },
    },
    {
        id: 'noc_copper',
        type: 'line',
        source: 'site_plan',
        zindex: 101,
        'source-layer': 'c23_noc_copper_linestring',
        paint: {
            'line-color': 'rgba(244, 112, 7, 1)',
        },
    },
    {
        id: 'nocprivate_dk_range',
        type: 'fill',
        zindex: 102,
        minzoom: 15,
        source: 'site_plan',
        'source-layer': 'c23_noc_dk_range_polygon',
        paint: {
            'fill-color': 'rgba(133, 85, 129, 0.18)',
        },
    },
    {
        id: 'noc_switch',
        type: 'circle',
        source: 'dk',
        filter: ['!', ['get', 'dk']],
        zindex: 103,
        minzoom: 15,
        paint: {
            'circle-color': [
                'case',
                ['get', 'alive'],
                '#3FFF21',
                ['all', ['get', 'active'], ['!', ['get', 'alive']]],
                '#FF494B',
                'rgba(171, 0, 181, 1)',
            ],
            'circle-stroke-color': 'rgba(47, 7, 57, 1)',
            'circle-stroke-width': 1,
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 15, 1, 20, 10],
        },
    },
    {
        id: 'noc_dk',
        type: 'symbol',
        source: 'dk',
        filter: ['get', 'dk'],
        zindex: 120,
        minzoom: 15,
        layout: {
            'icon-image': [
                'case',
                ['get', 'alive'],
                'datenklo_active',
                ['all', ['get', 'active'], ['!', ['get', 'alive']]],
                'datenklo_down',
                'datenklo',
            ],
            'icon-ignore-placement': true,
            'icon-allow-overlap': true,
            'icon-size': ['interpolate', ['linear'], ['zoom'], 15, 0.1, 19, 1],
            'icon-offset': [0, -25],
        },
    },
    {
        id: 'noc_ap',
        type: 'circle',
        source: 'site_plan',
        'source-layer': 'c23_noc_ap_point',
        minzoom: 15,
        zindex: 103,
        paint: {
            'circle-color': 'blue',
            'circle-stroke-color': 'rgba(47, 7, 57, 1)',
            'circle-stroke-width': 1,
            'circle-radius': 4,
        },
    },
    {
        id: 'noc_switch_label',
        type: 'symbol',
        source: 'site_plan',
        'source-layer': 'c23_noc_sw_point',
        minzoom: 17,
        zindex: 113,
        layout: {
            'text-font': ['Questrial Regular'],
            'text-field': '{switch}',
            'text-anchor': 'top',
            'text-offset': [0, 1],
            'text-size': ['interpolate', ['linear'], ['zoom'], 16, 9, 19, 15],
            'symbol-avoid-edges': false,
        },
        paint: {
            'text-halo-color': 'rgba(243, 243, 243, 0.95)',
            'text-halo-width': 2,
            'text-halo-blur': 1,
        },
    },
    {
        id: 'noc_fibers_cores',
        type: 'symbol',
        source: 'site_plan',
        'source-layer': 'c23_noc_2c_fiber_linestring',
        minzoom: 18,
        zindex: 112,
        layout: {
            'text-font': ['Questrial Regular'],
            'symbol-placement': 'line',
            'symbol-spacing': 500,
            'text-field': fiber_label,
            'text-offset': [0, 0.8],
            'text-max-angle': 20,
            'text-size': 13,
        },
        paint: {
            'text-color': 'rgba(0, 0, 0, 1)',
            'text-halo-color': 'rgba(234, 234, 234, 0.89)',
            'text-halo-width': 2,
        },
    },
    {
        id: 'noc_copper_label',
        type: 'symbol',
        source: 'site_plan',
        'source-layer': 'c23_noc_copper_linestring',
        minzoom: 18,
        zindex: 112,
        layout: {
            'text-font': ['Questrial Regular'],
            'symbol-placement': 'line',
            'text-field': 'copper',
            'text-offset': [0, 0.8],
            'text-max-angle': 20,
            'text-size': 13,
        },
        paint: {
            'text-color': 'rgba(0, 0, 0, 1)',
            'text-halo-color': 'rgba(234, 234, 234, 0.89)',
            'text-halo-width': 2,
        },
    },
]

export default style
