import { FilterSpecification } from 'maplibre-gl'
import { LayerSpecificationWithZIndex } from './types'

const trainFilter: FilterSpecification = ['!=', ['get', 'name'], 'demo']

const style: Array<LayerSpecificationWithZIndex> = [
    {
        id: 'trains',
        type: 'symbol',
        zindex: 110,
        minzoom: 16,
        filter: trainFilter,
        source: 'c3toc',
        layout: {
            'icon-image': ['case', ['<', ['get', 'dir'], 180], 'train_right', 'train_left'],
            'icon-rotate': [
                'case',
                ['>', ['get', 'speed'], 0.1],
                ['case', ['<', ['get', 'dir'], 180], ['-', ['get', 'dir'], 90], ['-', ['get', 'dir'], 270]],
                0,
            ],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': ['interpolate', ['linear'], ['zoom'], 16, 0.2, 22, 1],
        },
    },
    {
        id: 'trains-label',
        type: 'symbol',
        zindex: 110,
        filter: trainFilter,
        minzoom: 17.5,
        source: 'c3toc',
        layout: {
            'text-field': '{name}\n({speed} km/h)',
            'text-font': ['Questrial Regular'],
            'text-size': 12,
            'text-anchor': 'center',
            'text-offset': [
                'interpolate',
                ['linear'],
                ['zoom'],
                17.5,
                ['literal', [0, 3]],
                22,
                ['literal', [0, 6]],
            ],
        },
        paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.76)',
            'text-halo-width': 2,
            'text-halo-blur': 1,
        },
    },
    /*
    {
        id: 'track-label',
        source: 'site_plan',
        "source-layer": "tracks_linestring",
        type: 'symbol',
        zindex: 110,
        minzoom: 18,
        layout: {
            'text-field': '{name}',
            'text-font': ['Questrial Regular'],
            'text-size': 12,
            'symbol-placement': 'line',
            'symbol-spacing': 500,
            'text-max-angle': 10,
            'text-offset': [0, 0],
        },
        paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.9)',
            'text-halo-width': 2,
            'text-halo-blur': 1,
        }
    }
    */
]

export default style
