import './search.css'
import Fuse from 'fuse.js'
import { list, el, svg, setAttr, setStyle, List, RedomComponent } from 'redom'
import CCCampProjection from '../projection'
import { IControl, Map } from 'maplibre-gl'

const lang = navigator.language.toLowerCase().split('-')[0]

const icon = svg(
    'svg#icon-search',
    { viewBox: '0 0 512 512', class: 'search-icon' },
    svg('path', {
        d: 'M337.509 305.372h-17.501l-6.571-5.486c20.791-25.232 33.922-57.054 33.922-93.257C347.358 127.632 283.896 64 205.135 64 127.452 64 64 127.632 64 206.629s63.452 142.628 142.225 142.628c35.011 0 67.831-13.167 92.991-34.008l6.561 5.487v17.551L415.18 448 448 415.086 337.509 305.372zm-131.284 0c-54.702 0-98.463-43.887-98.463-98.743 0-54.858 43.761-98.742 98.463-98.742 54.7 0 98.462 43.884 98.462 98.742 0 54.856-43.762 98.743-98.462 98.743z',
    })
)

const icon_close = el(
    'button#icon-close',
    svg(
        'svg.search-icon',
        { viewBox: '0 0 512 512' },
        svg('path', {
            d: 'M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z',
        })
    )
)

interface SearchItem {
    gid: string
    text: string | undefined
    text_en: string | undefined
    name: string | undefined
    type: string
    position: [number, number]
}

interface SearchResult {
    result: Fuse.FuseResult<SearchItem>
    gid: string
}

class Search implements IControl {
    _fuse: Fuse<SearchItem> | null
    _list_size: number
    _selected: number
    _projection: CCCampProjection
    _searchBox: HTMLInputElement
    _map: Map | null
    _results: List

    constructor(data: Array<SearchItem>) {
        this._fuse = null
        this._list_size = 10
        this._selected = 0
        this._projection = new CCCampProjection()
        this._map = null
        this._searchBox = el('input#search', { placeholder: 'Search' }) as HTMLInputElement
        this._results = list('ul', ListItem, 'gid', this)
        this._addItems(data)
    }

    _addItems(data: Array<SearchItem>) {
        this._fuse = new Fuse(data, {
            shouldSort: true,
            threshold: 0.3,
            location: 0,
            distance: 100,
            minMatchCharLength: 1,
            keys: ['text', 'text_en', 'name'],
        })
    }

    _updateSearch() {
        if (!this._fuse) return
        const q = this._searchBox.value
        if (q.length > 0) {
            setStyle(icon_close, { display: 'block' })
            setStyle(this._results, { display: 'block' })
        } else {
            setStyle(icon_close, { display: 'none' })
            setStyle(this._results, { display: 'none' })
            return
        }
        const results = this._fuse.search(q).slice(0, this._list_size)
        this._results.update(results.map((result) => ({ result, gid: result.item.gid })))
        this._selected = 0
        this._updateSelected()
    }

    _updateSelected() {
        for (let i = 0; i < Math.min(this._results.views.length, this._list_size); i++) {
            this._results.views[i].updateSelected(i == this._selected)
        }
    }

    activate() {
        const item = this._results.views[this._selected]
        if (item) {
            item.activate()
        }
    }

    onAdd(map: Map) {
        this._map = map

        setAttr(this._results, { class: 'search-results' })
        const form = el('form', this._searchBox)
        const wrapper = el('div', icon, form, icon_close, this._results, {
            class: 'maplibregl-ctrl search-control',
        })

        form.addEventListener('submit', (event) => {
            this.activate()
            event.preventDefault()
        })

        this._searchBox.addEventListener('keydown', (event) => {
            if (event.code == 'ArrowDown') {
                this._selected = Math.min(this._selected + 1, this._list_size - 1)
                this._updateSelected()
                event.preventDefault()
            } else if (event.code == 'ArrowUp') {
                this._selected = Math.max(this._selected - 1, 0)
                this._updateSelected()
                event.preventDefault()
            } else if (event.code == 'Enter') {
                this.activate()
                event.preventDefault()
            } else if (event.code == 'Escape') {
                this._searchBox.value = ''
                event.preventDefault()
            }
        })

        this._searchBox.addEventListener('keyup', (event) => {
            if (event.code == 'ArrowUp' || event.code == 'ArrowDown' || event.code == 'Enter') {
                return
            }
            this._updateSearch()
        })
        icon_close.addEventListener('click', () => this.clearSearch())

        document.addEventListener('keydown', (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key == 'f') {
                event.preventDefault()
                this._searchBox.focus()
            }
        })
        return wrapper
    }

    onRemove() {}

    clearSearch() {
        this._searchBox.value = ''
        this._updateSearch()
    }
}

class ListItem implements RedomComponent {
    search: Search
    link: HTMLElement
    subtitle: HTMLElement
    el: HTMLElement
    _position: [number, number] | null

    constructor(search: Search) {
        this.search = search
        this.link = el('a', { href: '#' })
        this.subtitle = el('span')
        this.el = el('li', this.link, this.subtitle)
        this.el.addEventListener('click', (event) => {
            this.activate()
            event.preventDefault()
        })
        this._position = null
    }

    update(data: SearchResult) {
        const item = data.result.item
        const gridPos = this.search._projection.convertToGrid(item.position)
        let text = ''
        if (item['name']) {
            text = item['name']
        } else if (lang == 'de' || !item['text_en']) {
            text = item['text'] || ''
        } else if (item['text_en']) {
            text = item['text_en']
        }
        text = text.replace(/\n+/g, '<br>')
        this.link.innerHTML = `${text} <span class="grid-pos">${gridPos}</span>`
        this._position = item.position
    }

    updateSelected(selected: boolean) {
        if (selected) {
            setAttr(this.el, { class: 'selected' })
        } else {
            setAttr(this.el, { class: '' })
        }
    }

    activate() {
        if (!this._position || !this.search._map) {
            return
        }
        this.search._map.flyTo({
            center: this._position,
            zoom: 20,
        })
        this.search.clearSearch()
    }
}

export { Search as default }
