import { LayerSpecificationWithZIndex } from './types'

const style: Array<LayerSpecificationWithZIndex> = [
    {
        id: 'sanitation_fresh_pipe_case',
        zindex: 105,
        type: 'line',
        source: 'site_plan',
        'source-layer': 'c23_sanitaer_leitungen_frischwasser_linestring',
        paint: {
            'line-color': '#9C99CC',
            'line-width': 1,
            'line-gap-width': 2.4,
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
    },
    {
        id: 'sanitation_fresh_pipe',
        zindex: 105,
        type: 'line',
        source: 'site_plan',
        'source-layer': 'c23_sanitaer_leitungen_frischwasser_linestring',
        paint: {
            'line-color': '#4339CC',
            'line-width': 2,
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
    },
    {
        id: 'sanitation_fresh_tank',
        zindex: 105,
        type: 'fill',
        source: 'site_plan',
        'source-layer': 'c23_sanitaer_leitungen_frischwasser_polygon',
        paint: {
            'fill-color': '#4339CC',
            'fill-antialias': true,
        },
    },
    {
        id: 'sanitation_sewage_pipe_case',
        zindex: 105,
        type: 'line',
        source: 'site_plan',
        'source-layer': 'c23_sanitaer_leitungen_abwasser_linestring',
        paint: {
            'line-color': '#A1938D',
            'line-width': 1,
            'line-gap-width': 2.4,
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
    },
    {
        id: 'sanitation_sewage_pipe',
        zindex: 105,
        type: 'line',
        source: 'site_plan',
        'source-layer': 'c23_sanitaer_leitungen_abwasser_linestring',
        paint: {
            'line-color': '#A14727',
            'line-width': 2,
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
    },
    {
        id: 'sanitation_sewage_tank',
        zindex: 105,
        type: 'fill',
        source: 'site_plan',
        'source-layer': 'c23_sanitaer_leitungen_abwasser_polygon',
        paint: {
            'fill-color': '#A14727',
            'fill-antialias': true,
        },
    },
]

export default style
