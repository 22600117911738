import type { ColourScheme, LayerSpecificationWithZIndex } from './types'

export default function generateStyle(colours: ColourScheme): Array<LayerSpecificationWithZIndex> {
    return [
        {
            id: 'background',
            type: 'background',
            zindex: -999,
            paint: {
                'background-color': colours['background'],
            },
        },
        {
            id: 'base_landcover_wood',
            zindex: 19,
            type: 'fill',
            source: 'openmaptiles',
            'source-layer': 'landcover',
            filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'wood']],
            paint: {
                'fill-color': colours['landcover-wood'],
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0.5, 20, 1],
            },
        },
        {
            id: 'base_landcover_wood_outline',
            zindex: 19,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'landcover',
            filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'wood']],
            paint: {
                'line-color': colours['landcover-wood-outline'],
                'line-width': 2,
                'line-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0, 20, 1],
            },
        },
        {
            id: 'base_landcover_grass',
            zindex: 5,
            type: 'fill',
            source: 'openmaptiles',
            'source-layer': 'landcover',
            filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'grass']],
            paint: {
                'fill-color': colours['landcover-grass'],
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0.5, 12, 0.6],
            },
        },
        {
            id: 'base_landcover_farmland',
            zindex: 5,
            type: 'fill',
            source: 'openmaptiles',
            'source-layer': 'landcover',
            filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'farmland']],
            paint: {
                'fill-color': colours['landcover-farmland'],
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0.5, 12, 1],
            },
        },
        {
            id: 'base_landuse_residential',
            zindex: 6,
            type: 'fill',
            source: 'openmaptiles',
            'source-layer': 'landuse',
            maxzoom: 16,
            filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'residential']],
            layout: {},
            paint: {
                'fill-color': colours['landuse-residential'],
                'fill-opacity': ['interpolate', ['exponential', 0.6], ['zoom'], 8, 0.8, 9, 0.6],
            },
        },
        {
            id: 'base_building',
            zindex: 7,
            type: 'fill',
            source: 'openmaptiles',
            'source-layer': 'building',
            minzoom: 12,
            paint: {
                'fill-color': colours['building'],
                'fill-outline-color': colours['building-outline'],
                'fill-antialias': true,
            },
        },
        {
            id: 'base_tunnel_motorway_casing',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 6,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'motorway']],
            ],
            layout: {
                'line-cap': 'butt',
                'line-join': 'miter',
            },
            paint: {
                'line-color': colours['motorway-casing'],
                'line-width': ['interpolate', ['exponential', 1.4], ['zoom'], 5.8, 0, 6, 3, 20, 40],
            },
        },
        {
            id: 'base_tunnel_motorway_inner',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 6,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'motorway']],
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': colours['motorway-inner'],
                'line-width': ['interpolate', ['exponential', 1.4], ['zoom'], 4, 2, 6, 1.3, 20, 30],
            },
        },
        {
            id: 'base_aeroway-taxiway',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'aeroway',
            minzoom: 12,
            filter: ['all', ['in', 'class', 'taxiway']],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': 'hsl(0, 0%, 88%)',
                'line-width': ['interpolate', ['exponential', 1.55], ['zoom'], 13, 1.8, 20, 20],
            },
        },
        {
            id: 'base_aeroway-runway-casing',
            zindex: 9,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'aeroway',
            minzoom: 11,
            filter: ['all', ['in', 'class', 'runway']],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': 'hsl(0, 0%, 88%)',
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 11, 4, 17, 55],
            },
        },
        {
            id: 'base_aeroway-area',
            zindex: 10,
            type: 'fill',
            source: 'openmaptiles',
            'source-layer': 'aeroway',
            minzoom: 4,
            filter: ['all', ['==', '$type', 'Polygon'], ['in', 'class', 'runway', 'taxiway']],
            layout: {},
            paint: {
                'fill-opacity': ['interpolate', ['linear'], ['zoom'], 13, 0, 14, 1],
                'fill-color': 'rgba(255, 255, 255, 1)',
            },
        },
        {
            id: 'base_aeroway-runway',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'aeroway',
            minzoom: 11,
            maxzoom: 24,
            filter: ['all', ['in', 'class', 'runway'], ['==', '$type', 'LineString']],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': 'rgba(255, 255, 255, 1)',
                'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 11, 4, 17, 50],
            },
        },
        {
            id: 'base_highway_minor',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 8,
            filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'minor', 'service', 'track']],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': colours['highway-minor'],
                'line-width': ['interpolate', ['exponential', 1.55], ['zoom'], 13, 1.8, 20, 20],
                'line-opacity': 0.9,
            },
        },
        {
            id: 'base_highway_major_casing',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 11,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk'],
            ],
            layout: {
                'line-cap': 'butt',
                'line-join': 'miter',
            },
            paint: {
                'line-color': colours['highway-major-casing'],
                'line-dasharray': [12, 0],
                'line-width': ['interpolate', ['exponential', 1.3], ['zoom'], 10, 3, 20, 23],
            },
        },
        {
            id: 'base_highway_major_inner',
            zindex: 11,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 11,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk'],
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': colours['highway-major-inner'],
                'line-width': ['interpolate', ['exponential', 1.3], ['zoom'], 10, 2, 20, 20],
            },
        },
        {
            id: 'base_highway_major_subtle',
            type: 'line',
            zindex: 10,
            source: 'openmaptiles',
            'source-layer': 'transportation',
            maxzoom: 11,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk'],
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': 'hsla(0, 0%, 85%, 0.69)',
                'line-width': 2,
            },
        },
        {
            id: 'base_highway_motorway_casing',
            zindex: 9,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 6,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway']],
            ],
            layout: {
                'line-cap': 'butt',
                'line-join': 'miter',
            },
            paint: {
                'line-color': 'rgb(213, 213, 213)',
                'line-width': ['interpolate', ['exponential', 1.4], ['zoom'], 5.8, 0, 6, 3, 20, 40],
                'line-dasharray': [2, 0],
            },
        },
        {
            id: 'base_highway_motorway_inner',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 6,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway']],
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': ['interpolate-hcl', ['linear'], ['zoom'], 6, 'hsla(0, 0%, 85%, 0.53)', 8, '#fff'],
                'line-width': ['interpolate', ['exponential', 1.4], ['zoom'], 4, 2, 6, 1.3, 20, 30],
            },
        },
        {
            id: 'base_highway_motorway_subtle',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            maxzoom: 6,
            filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': 'hsla(0, 0%, 85%, 0.53)',
                'line-width': ['interpolate', ['exponential', 1.4], ['zoom'], 4, 2, 6, 1.3],
            },
        },
        {
            id: 'base_railway_transit',
            zindex: 15,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 16,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']],
            ],
            layout: {
                'line-join': 'round',
            },
            paint: {
                'line-color': '#dddddd',
                'line-width': 3,
            },
        },
        {
            id: 'base_railway_transit_dashline',
            zindex: 16,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 16,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']],
            ],
            layout: {
                'line-join': 'round',
            },
            paint: {
                'line-color': '#fafafa',
                'line-width': 2,
                'line-dasharray': [3, 3],
            },
        },
        {
            id: 'base_railway_service',
            zindex: 15,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 16,
            filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'rail'], ['has', 'service']]],
            layout: {
                'line-join': 'round',
            },
            paint: {
                'line-color': '#dddddd',
                'line-width': 3,
            },
        },
        {
            id: 'base_railway_service_dashline',
            zindex: 16,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 16,
            filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'rail'], ['has', 'service']],
            layout: {
                'line-join': 'round',
            },
            paint: {
                'line-color': '#fafafa',
                'line-width': 2,
                'line-dasharray': [3, 3],
            },
        },
        {
            id: 'base_railway',
            zindex: 15,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 8,
            filter: ['all', ['==', '$type', 'LineString'], ['all', ['!has', 'service'], ['==', 'class', 'rail']]],
            layout: {
                'line-join': 'round',
            },
            paint: {
                'line-color': '#999999',
                'line-width': ['interpolate', ['exponential', 1.3], ['zoom'], 9, 2, 20, 7],
            },
        },
        {
            id: 'base_railway_dashline',
            zindex: 16,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 8,
            filter: ['all', ['==', '$type', 'LineString'], ['all', ['!has', 'service'], ['==', 'class', 'rail']]],
            layout: {
                'line-join': 'round',
            },
            paint: {
                'line-color': '#dddddd',
                'line-width': ['interpolate', ['exponential', 1.3], ['zoom'], 9, 1, 20, 6],
                'line-dasharray': [3, 3],
            },
        },
        {
            id: 'base_railway_station',
            zindex: 20,
            type: 'circle',
            source: 'openmaptiles',
            'source-layer': 'poi',
            minzoom: 12,
            filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'railway'], ['==', 'subclass', 'station']],
            paint: {
                'circle-radius': ['interpolate', ['linear'], ['zoom'], 12, 3, 18, 16],
                'circle-color': '#999999',
                'circle-stroke-color': '#333333',
                'circle-stroke-width': 1,
            },
        },
        {
            id: 'base_railway_station_label',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'poi',
            minzoom: 13,
            zindex: 130,
            filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'railway'], ['==', 'subclass', 'station']],
            layout: {
                'text-field': '{name:latin}\n{name:nonlatin}',
                'text-font': ['Questrial Regular'],
                'text-size': 14,
                'text-anchor': 'top',
                'text-offset': [0, 1.5],
                'text-max-width': 9,
                'text-optional': true,
                'text-allow-overlap': true,
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
                'text-halo-blur': 0.5,
            },
        },
        {
            id: 'base_highway_motorway_bridge_casing',
            zindex: 10,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 6,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway']],
            ],
            layout: {
                'line-cap': 'butt',
                'line-join': 'miter',
            },
            paint: {
                'line-color': 'rgb(213, 213, 213)',
                'line-width': ['interpolate', ['exponential', 1.4], ['zoom'], 5.8, 0, 6, 5, 20, 45],
                'line-dasharray': [2, 0],
            },
        },
        {
            id: 'base_highway_motorway_bridge_inner',
            zindex: 11,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'transportation',
            minzoom: 6,
            filter: [
                'all',
                ['==', '$type', 'LineString'],
                ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway']],
            ],
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': ['interpolate-hcl', ['linear'], ['zoom'], 5.8, 'hsla(0, 0%, 85%, 0.53)', 6, '#fff'],
                'line-width': ['interpolate', ['exponential', 1.4], ['zoom'], 4, 2, 6, 1.3, 20, 30],
            },
        },
        {
            id: 'base_highway_name_motorway',
            zindex: 30,
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'transportation_name',
            filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
            layout: {
                'text-size': 10,
                'symbol-spacing': 350,
                'text-font': ['Questrial Regular'],
                'symbol-placement': 'line',
                'text-rotation-alignment': 'viewport',
                'text-pitch-alignment': 'viewport',
                'text-field': '{ref}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-translate': [0, 2],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_other',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 14,
            zindex: 120,
            filter: [
                'all',
                ['in', 'class', 'continent', 'hamlet', 'neighbourhood', 'isolated_dwelling'],
                ['==', '$type', 'Point'],
            ],
            layout: {
                'text-size': ['interpolate', ['linear'], ['zoom'], 10, 8, 14, 12],
                'text-font': ['Questrial Regular'],
                'text-justify': 'center',

                'text-offset': [0.5, 0],
                'text-anchor': 'center',
                'text-field': '{name}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_suburb',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 15,
            zindex: 120,
            filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'suburb']],
            layout: {
                'text-size': 10,
                'text-transform': 'uppercase',
                'text-font': ['Questrial Regular'],
                'text-justify': 'center',

                'text-offset': [0.5, 0],
                'text-anchor': 'center',
                'text-field': '{name}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_village',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 14,
            zindex: 120,
            filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'village']],
            layout: {
                'text-size': ['interpolate', ['linear'], ['zoom'], 10, 12, 14, 17],
                'text-font': ['Questrial Regular'],
                'text-offset': [0.5, 0.2],
                'text-anchor': 'center',
                'text-field': '{name:latin}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_town',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 15,
            zindex: 120,
            filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'town']],
            layout: {
                'text-size': ['interpolate', ['linear'], ['zoom'], 10, 12, 14, 17],
                'text-transform': 'uppercase',
                'text-font': ['Questrial Regular'],
                'text-justify': 'left',
                'text-offset': [0.5, 0.2],
                'text-anchor': 'center',
                'text-field': '{name:latin}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_city',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 14,
            zindex: 120,
            filter: [
                'all',
                ['==', '$type', 'Point'],
                ['all', ['!=', 'capital', 2], ['==', 'class', 'city'], ['>', 'rank', 3]],
            ],
            layout: {
                'text-size': 10,
                'text-transform': 'uppercase',
                'text-font': ['Questrial Regular'],
                'text-justify': 'left',
                'text-offset': [0.5, 0.2],
                'icon-size': 0.4,
                'text-anchor': 'center',
                'text-field': '{name:latin}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_capital',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 12,
            zindex: 120,
            filter: ['all', ['==', '$type', 'Point'], ['all', ['==', 'capital', 2], ['==', 'class', 'city']]],
            layout: {
                'text-size': 14,
                'text-transform': 'uppercase',
                'text-font': ['Questrial Regular'],
                'text-justify': 'left',
                'text-offset': [0.5, 0.2],
                'text-anchor': 'center',
                'text-field': '{name:latin}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_city_large',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 12,
            zindex: 120,
            filter: [
                'all',
                ['==', '$type', 'Point'],
                ['all', ['!=', 'capital', 2], ['<=', 'rank', 3], ['==', 'class', 'city']],
            ],
            layout: {
                'text-size': 14,
                'text-transform': 'uppercase',
                'text-font': ['Questrial Regular'],
                'text-justify': 'left',
                'text-offset': [0.5, 0.2],
                'text-anchor': 'center',
                'text-field': '{name:latin}',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_place_state',
            type: 'symbol',
            source: 'openmaptiles',
            'source-layer': 'place',
            maxzoom: 12,
            zindex: 120,
            filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'state']],
            layout: {
                'text-field': '{name}',
                'text-font': ['Questrial Regular'],
                'text-transform': 'uppercase',
                'text-size': 10,
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
        {
            id: 'base_water_line',
            zindex: 20,
            type: 'line',
            source: 'openmaptiles',
            minzoom: 15,
            'source-layer': 'water',
            filter: ['==', '$type', 'Polygon'],
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': '#538A7B',
                'line-width': 4,
                'line-offset': -1,
                'line-blur': 4,
            },
        },

        {
            id: 'base_waterway',
            zindex: 20,
            type: 'line',
            source: 'openmaptiles',
            'source-layer': 'waterway',
            filter: ['all', ['==', '$type', 'LineString']],
            paint: {
                'line-color': colours['waterway'],
                'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 20, 10],
                'line-blur': 1,
            },
        },
        {
            id: 'base_water_outline',
            zindex: 19,
            type: 'line',
            minzoom: 15,
            source: 'openmaptiles',
            'source-layer': 'water',
            filter: ['==', '$type', 'Polygon'],
            paint: {
                'line-color': colours['water-outline'],
                'line-width': 5,
            },
        },
        {
            id: 'base_water',
            zindex: 20,
            type: 'fill',
            source: 'openmaptiles',
            'source-layer': 'water',
            filter: ['==', '$type', 'Polygon'],
            layout: {},
            paint: {
                'fill-color': colours['water'],
                'fill-antialias': true,
            },
        },
        {
            id: 'base_water_name',
            zindex: 101,
            type: 'symbol',
            minzoom: 14,
            source: 'openmaptiles',
            'source-layer': 'water_name',
            layout: {
                'text-field': '{name:latin}',
                'text-rotation-alignment': 'map',
                'symbol-spacing': 500,
                'text-font': ['Questrial Regular'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 14, 10, 18, 14],
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 1,
                'text-halo-blur': 1,
            },
        },
    ]
}