import sortBy from 'lodash.sortby'

import bus_routes from './data/bus_routes.json' assert { type: 'json' }
import base from './style/base'
import ortho from './style/ortho'
import grid from './style/grid'
import main from './style/main'
import noc from './style/noc'
import bus from './style/bus'
import power from './style/power'
import sanitation from './style/sanitation'
import c3toc from './style/c3toc'
import { colours, darkColours } from './style/colours'
import { SourceSpecification, StyleSpecification } from 'maplibre-gl'

const outline = {
    type: 'Feature',
    properties: {},
    geometry: {
        type: 'Polygon',
        coordinates: [
            [
                [13.3120997812022, 53.027569178401308],
                [13.311185927280553, 53.027649143555593],
                [13.306654001012044, 53.027922575549738],
                [13.301310304290613, 53.028050075493304],
                [13.301252323744684, 53.028788431222196],
                [13.301260089912947, 53.029353352776539],
                [13.30141723752339, 53.029577821730136],
                [13.303494276930245, 53.031781350829448],
                [13.303602760952412, 53.032438587258859],
                [13.303840103123743, 53.032793564976686],
                [13.304316607736718, 53.034048089755707],
                [13.304761562356443, 53.034399809191626],
                [13.305337316786387, 53.034382593319705],
                [13.305891660901642, 53.034548432178312],
                [13.306951760996135, 53.034599965272136],
                [13.307843535291388, 53.034539784027302],
                [13.308306299915385, 53.034299118945981],
                [13.308594340753862, 53.03388939968994],
                [13.308666968440241, 53.033578118465762],
                [13.30879403921074, 53.033530475837189],
                [13.309111175388461, 53.033480330360852],
                [13.312643632388143, 53.033460232063078],
                [13.312677952267885, 53.033244701831052],
                [13.312525572930383, 53.03206095170296],
                [13.312733405993402, 53.031382014892294],
                [13.313780126565415, 53.030668128312648],
                [13.314827531242967, 53.030157246474154],
                [13.315356112634001, 53.029222588202515],
                [13.315598580097886, 53.028096486873068],
                [13.316617057762247, 53.027439433266544],
                [13.313234626355062, 53.027020821056588],
                [13.3120997812022, 53.027569178401308],
            ],
        ],
    },
}

/**
 * Generate MapLibre GL style specification. This is called by the main JS app but also by the export_style script to
 * produce a static style JSON for other uses.
 */
function generateStyle(
    type: string,
    hostname: string,
    baseUrl: string,
    colourTheme: string = 'light'
): StyleSpecification {
    let col = colours
    if (colourTheme == 'dark') {
        col = darkColours
    }

    let layers = null
    if (type == 'full') {
        layers = base(col).concat(ortho, grid, main(col), noc, power, sanitation, bus(col), c3toc)
    } else {
        layers = base(col).concat(main(col))
    }

    const host = hostname + baseUrl

    const all_sources: Record<string, SourceSpecification> = {
        openmaptiles: {
            type: 'vector',
            url: 'pmtiles://' + host + '/static_sources/brandenburg.pmtiles',
        },
        site_plan: {
            type: 'vector',
            url: 'pmtiles://' + host + '/static_sources/buildmap.pmtiles',
        },
        bus_routes: {
            type: 'geojson',
            data: bus_routes,
        },
        camp_centroid: {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [13.30688, 53.03136],
                },
            },
        },
        outline: {
            type: 'geojson',
            data: outline,
        },
        ortho: {
            type: 'raster',
            url: 'pmtiles://' + host + '/static_sources/ziegeleipark-ortho.pmtiles',
            tileSize: 256,
        },
        hillshade: {
            type: 'raster',
            url: 'pmtiles://' + host + '/static_sources/hillshade.pmtiles',
            tileSize: 256,
        },
        dk: {
            type: 'geojson',
            data: host + 'noc/dk.json',
        },
        c3toc: {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
        },
    }

    // Remove unused sources from style
    const used_sources = new Set(layers.map((layer: any) => layer?.source))
    used_sources.delete(undefined)

    for (const source in all_sources) {
        if (!used_sources.has(source)) {
            delete all_sources[source]
        }
    }

    return {
        version: 8,
        name: 'CCCamp 2023',
        center: [13.30688, 53.03136],
        zoom: 16,
        bearing: 0,
        pitch: 0,
        sources: all_sources,
        layers: sortBy(layers, [(item) => item.zindex || 0]).map((item) => {
            const { zindex: _, ...new_item } = item
            return new_item
        }),
        glyphs: host + 'fonts/{fontstack}/{range}.pbf',
        sprite: host + 'sprites/sprites',
    }
}

export default generateStyle
