import { ColourScheme, LayerSpecificationWithZIndex } from './types'

export default function generateStyle(colours: ColourScheme): Array<LayerSpecificationWithZIndex> {
    return [
        {
            id: 'bus_stops_icon',
            type: 'symbol',
            zindex: 110,
            minzoom: 10,
            source: 'bus_routes',
            layout: {
                'icon-image': [
                    'match',
                    ['get', 'route'],
                    'gransee',
                    'bus_gransee',
                    'zehdenick',
                    'bus_zehdenick',
                    'bus_camp',
                ],
                'icon-offset': [0, -10],
                'icon-overlap': 'always',
                'icon-size': ['interpolate', ['linear'], ['zoom'], 17, 0.5, 18, 1],
            },
        },
        {
            id: 'bus_stops_label',
            type: 'symbol',
            zindex: 110,
            minzoom: 14,
            source: 'bus_routes',
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
            },
            layout: {
                'text-offset': [0, 2],
                'text-field': '{name}',
                'text-font': ['Questrial Regular'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 14, 8, 20, 20],
            },
        },
    ]
}