import { LayerSpecificationWithZIndex } from './types'

const style: Array<LayerSpecificationWithZIndex> = [
    {
        id: 'ortho',
        type: 'raster',
        zindex: 17,
        source: 'ortho',
        paint: {
            'raster-resampling': 'nearest',
        },
    },
    {
        id: 'hillshade',
        zindex: 18,
        type: 'raster',
        source: 'hillshade',
        paint: {
            'raster-brightness-min': 0.5,
            'raster-resampling': 'nearest',
        },
    },
    {
        id: 'hillshade_background',
        zindex: 18.5,
        source: 'outline',
        type: 'fill',
        paint: {
            'fill-color': 'hsla(120, 90%, 95%, 0.3)',
        },
    },
]

export default style
