import { LayerSpecificationWithZIndex } from './types'

const style: Array<LayerSpecificationWithZIndex> = [
    {
        id: 'power_areas_outline',
        type: 'line',
        zindex: 100,
        source: 'site_plan',
        'source-layer': 'c23_strom_bereichsplanung_linestring',
        paint: {
            'line-color': 'hsla(30, 100%, 50%, 1)',
            'line-width': 2,
        },
    },
    {
        id: 'power_crossings',
        type: 'line',
        zindex: 110,
        minzoom: 17,
        source: 'site_plan',
        'source-layer': 'c23_strom_ueberwege_polygon',
        paint: {
            'line-color': 'hsla(1, 30%, 50%, 1)',
            'line-width': 2,
        },
    },
    {
        id: 'power_distro_symbol',
        type: 'circle',
        zindex: 110,
        minzoom: 16,
        source: 'site_plan',
        'source-layer': 'c23_strom_verteilungen_point',
        paint: {
            'circle-translate': [
                'interpolate',
                ['linear'],
                ['zoom'],
                16,
                ['literal', [16, 0]],
                19,
                ['literal', [45, 0]],
            ],
            'circle-radius': ['interpolate', ['linear'], ['zoom'], 16, 2, 20, 8],
            'circle-color': '#D13648',
            'circle-stroke-color': '#333333',
            'circle-stroke-width': 1,
        },
    },
    {
        id: 'power_crossings_label',
        type: 'symbol',
        zindex: 110,
        minzoom: 18.5,
        source: 'site_plan',
        'source-layer': 'c23_strom_ueberwege_point',
        layout: {
            'text-font': ['Questrial Regular'],
            'text-field': '{text}',
            'text-anchor': 'top-left',
            'text-offset': [1, 2],
            'text-size': 18,
        },
        paint: {
            'text-color': '#222222',
            'text-halo-color': '#eeeeee',
            'text-halo-width': 2,
        },
    },
    {
        id: 'power_distro_label',
        type: 'symbol',
        zindex: 110,
        minzoom: 17,
        source: 'site_plan',
        'source-layer': 'c23_strom_verteilungen_point',
        layout: {
            'text-font': ['Questrial Regular'],
            'text-field': '{text}',
            'text-anchor': 'top-left',
            'text-offset': [0, 1],
            'text-size': ['interpolate', ['linear'], ['zoom'], 16, 10, 19, 14],
        },
        paint: {
            'text-color': '#222222',
            'text-halo-color': '#eeeeee',
            'text-halo-width': 2,
        },
    },
]

export default style
