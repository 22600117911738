import { DataDrivenPropertyValueSpecification } from 'maplibre-gl'
import { ColourScheme, LayerSpecificationWithZIndex } from './types'

let lang = 'en'
let moreLayers = false
if (typeof navigator !== 'undefined') {
    lang = navigator.language.toLowerCase().split('-')[0]
    moreLayers = localStorage.getItem('showmorelayers') == 'yes'
}

const translated_text: DataDrivenPropertyValueSpecification<string> =
    lang == 'de' ? ['get', 'text'] : ['case', ['has', 'text_en'], ['get', 'text_en'], ['get', 'text']]

export default function generateStyle(colours: ColourScheme): Array<LayerSpecificationWithZIndex> {
    return [
        {
            id: 'background_mask',
            zindex: 17.5,
            source: 'outline',
            type: 'fill',
            paint: {
                'fill-color': colours['camp-background'],
            },
        },
        {
            id: 'background_concrete',
            type: 'fill',
            source: 'site_plan',
            zindex: 70,
            minzoom: 12,
            'source-layer': 'betonfläche_polygon',
            layout: {},
            paint: {
                'fill-color': colours['concrete'],
            },
        },
        {
            id: 'villages_areas',
            type: 'fill',
            source: 'site_plan',
            zindex: 18.9,
            minzoom: 12,
            'source-layer': 'villages_area_polygon',
            layout: {},
            paint: {
                'fill-color': colours['village-camping-areas'],
            },
        },
        {
            id: 'main_free_camping',
            type: 'fill',
            source: 'site_plan',
            zindex: 18.9,
            minzoom: 12,
            'source-layer': 'c23_camping_polygon',
            layout: {},
            paint: {
                'fill-color': colours['free-camping-areas'],
            },
        },
        {
            id: 'background_parking_areas',
            type: 'fill',
            source: 'site_plan',
            zindex: 18.7,
            minzoom: 12,
            'source-layer': 'parking_polygon',
            layout: {},
            paint: {
                'fill-color': colours['parking-areas'],
            },
        },
        {
            id: 'main_backstage',
            type: 'fill',
            source: 'site_plan',
            zindex: 18.9,
            minzoom: 12,
            'source-layer': 'backstage_polygon',
            layout: {},
            paint: {
                'fill-color': 'hsla(0, 50%, 80%, 0.5)',
            },
        },
        {
            id: 'main_streets_case',
            type: 'line',
            source: 'site_plan',
            minzoom: 16,
            zindex: 90,
            'source-layer': 'streets_linestring',
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': colours['streets-case'],
                'line-gap-width': ['interpolate', ['linear'], ['zoom'], 17, 4, 20, 35],
                'line-width': 2,
            },
        },
        {
            id: 'main_streets_grass',
            type: 'line',
            filter: ['==', ['get', 'surface'], 'grass'],
            source: 'site_plan',
            minzoom: 12,
            zindex: 90,
            'source-layer': 'streets_linestring',
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': colours['streets-inner-grass'],
                'line-width': ['interpolate', ['linear'], ['zoom'], 17, 4, 20, 35],
            },
        },
        {
            id: 'main_streets_paved',
            type: 'line',
            filter: ['!=', ['get', 'surface'], 'grass'],
            source: 'site_plan',
            minzoom: 12,
            zindex: 90,
            'source-layer': 'streets_linestring',
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': colours['streets-inner'],
                'line-width': ['interpolate', ['linear'], ['zoom'], 17, 4, 20, 35],
            },
        },
        {
            id: 'trash_routes',
            type: 'line',
            source: 'site_plan',
            zindex: 101,
            'source-layer': 'c23_trash_route_linestring',
            minzoom: 12,
            paint: {
                'line-color': 'hsla(0, 80%, 50%, 1)',
                'line-width': ['interpolate', ['linear'], ['zoom'], 17, 2, 20, 4],
                'line-dasharray': [3, 2],
            },
        },
        {
            id: 'main_rails_active',
            type: 'line',
            source: 'site_plan',
            zindex: 101,
            'source-layer': 'active_rails_linestring',
            minzoom: 12,
            paint: {
                'line-color': colours['rails-active'],
                'line-width': ['interpolate', ['linear'], ['zoom'], 17, 2, 20, 4],
                'line-dasharray': [3, 2],
            },
        },
        {
            id: 'main_rails_polygon',
            type: 'fill',
            source: 'site_plan',
            zindex: 101,
            minzoom: 15,
            'source-layer': 'gleis_polygon',
            paint: {
                'fill-color': colours['rails'],
            },
        },
        {
            id: 'main_rails',
            type: 'line',
            source: 'site_plan',
            zindex: 99,
            minzoom: 14,
            'source-layer': 'gleis_linestring',
            layout: {},
            paint: {
                'line-color': colours['rails'],
                'line-width': ['interpolate', ['linear'], ['zoom'], 17, 1, 20, 4],
            },
        },
        {
            id: 'main_walls',
            zindex: 90,
            type: 'line',
            source: 'site_plan',
            'source-layer': 'mauer_linestring',
            minzoom: 15,
            maxzoom: 24,
            paint: {
                'line-color': colours['walls'],
            },
        },
        {
            id: 'main_camp_buildings_roof',
            type: 'line',
            source: 'site_plan',
            minzoom: 12,
            zindex: 100,
            'source-layer': 'schraffur_gebaeu_linestring',
            paint: {
                'line-color': colours['buildings-roof'],
            },
        },
        {
            id: 'main_camp_buildings_linestring',
            type: 'line',
            source: 'site_plan',
            minzoom: 12,
            zindex: 101,
            'source-layer': 'gebaeude_umrisse_linestring',
            paint: {
                'line-color': colours['camp-buildings-outline'],
            },
        },
        {
            id: 'main_camp_buildings_polygon',
            type: 'fill',
            source: 'site_plan',
            minzoom: 12,
            zindex: 100,
            'source-layer': 'gebaeude_umrisse_polygon',
            paint: {
                'fill-color': colours['structures-fill'],
            },
        },
        {
            id: 'main_camp_buildings_outline',
            type: 'line',
            source: 'site_plan',
            minzoom: 12,
            zindex: 100,
            'source-layer': 'gebaeude_umrisse_polygon',
            paint: {
                'line-color': colours['structures-outline'],
            },
        },
        {
            id: 'main_structures',
            zindex: 100,
            type: 'fill',
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'structures_polygon',
            paint: {
                'fill-color': colours['structures-fill'],
            },
        },
        {
            id: 'main_structures_outline',
            zindex: 100,
            type: 'line',
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'structures_polygon',
            paint: {
                'line-color': colours['structures-outline'],
            },
        },
        {
            id: 'villages_structures',
            zindex: 100,
            type: 'fill',
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'village_structures_polygon',
            paint: {
                'fill-color': colours['structures-fill'],
            },
        },
        {
            id: 'villages_structures_outline',
            zindex: 100,
            type: 'line',
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'village_structures_polygon',
            paint: {
                'line-color': colours['structures-outline'],
            },
        },
        {
            id: 'other_structures',
            zindex: 100,
            type: 'fill',
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'c23_sonstige_aufbauten_polygon',
            paint: {
                'fill-color': colours['structures-fill'],
            },
        },
        {
            id: 'other_structures_outline',
            zindex: 100,
            type: 'line',
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'c23_sonstige_aufbauten_polygon',
            paint: {
                'line-color': colours['structures-outline'],
            },
        },
        {
            id: 'main_structures_line',
            zindex: 100,
            type: 'line',
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'structures_linestring',
            layout: {},
            paint: {
                'line-color': colours['structures-outline'],
            },
        },
        {
            id: 'main_toilets',
            zindex: 100,
            type: 'fill',
            source: 'site_plan',
            minzoom: 17,
            'source-layer': 'sanitation_polygon',
            paint: {
                'fill-color': colours['toilets'],
                'fill-opacity': 0.1,
            },
        },
        {
            id: 'main_toilets_outline',
            zindex: 100,
            type: 'line',
            source: 'site_plan',
            minzoom: 17,
            'source-layer': 'sanitation_polygon',
            paint: {
                'line-color': colours['toilets'],
            },
        },
        {
            id: 'main_fence',
            type: 'line',
            zindex: 105,
            source: 'site_plan',
            minzoom: 12,
            'source-layer': 'zaun_linie_linestring',
            paint: {
                'line-color': colours['fence'],
                'line-width': 2,
            },
        },
        {
            id: 'woodpecker_polygon',
            zindex: 100,
            type: 'fill',
            source: 'site_plan',
            minzoom: 17,
            'source-layer': 'c23_woodpecker_polygon',
            paint: {
                'fill-color': 'hsl(19.3,60%,42.7%)',
                'fill-opacity': 0.8,
            },
        },
        {
            id: 'art_marker',
            type: 'circle',
            zindex: 120,
            source: 'site_plan',
            minzoom: 17,
            'source-layer': 'art_point',
            paint: {
                'circle-color': 'hsl(60, 50%, 50%)',
                'circle-radius': 5,
                'circle-stroke-color': 'hsl(60, 0%, 50%)',
                'circle-stroke-width': 2,
            },
        },
        {
            id: 'art_marker_label',
            type: 'symbol',
            zindex: 120,
            source: 'site_plan',
            minzoom: 17,
            'source-layer': 'art_point',
            layout: {
                'text-field': '{name}',
                'text-font': ['Questrial Regular'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 17, 10, 20, 20],
                'text-offset': [0, 1],
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
            },
        },
        {
            id: 'gates_fill',
            type: 'fill',
            source: 'site_plan',
            minzoom: 16,
            zindex: 100,
            'source-layer': 'c23_tore_polygon',
            paint: {
                'fill-color': 'hsla(10, 60%, 50%, 0.5)',
            },
        },
        {
            id: 'gates_number',
            type: 'symbol',
            source: 'site_plan',
            minzoom: 16,
            zindex: 105,
            'source-layer': 'c23_tore_point',
            layout: {
                'text-field': '{text}',
                'text-font': ['Questrial Regular'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 16, 15, 20, 25],
                'text-anchor': 'top',
                'text-allow-overlap': true,
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
            },
        },
        {
            id: 'main_labels_major',
            minzoom: 16,
            zindex: 109,
            type: 'symbol',
            source: 'site_plan',
            'source-layer': 'poi_point',
            filter: ['all', ['==', ['get', 'type'], 'label'], ['coalesce', ['get', 'major'], false]],
            layout: {
                'text-field': translated_text,
                'text-font': ['Questrial Regular'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 16, 15, 20, 25],
                'text-anchor': 'center',
                'text-allow-overlap': true,
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
            },
        },
        {
            id: 'main_labels_minor',
            minzoom: 17,
            zindex: 109,
            type: 'symbol',
            source: 'site_plan',
            'source-layer': 'poi_point',
            filter: ['all', ['==', ['get', 'type'], 'label'], ['!', ['coalesce', ['get', 'major'], false]]],
            layout: {
                'text-field': translated_text,
                'text-font': ['Questrial Regular'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 17, 14, 20, 22],
                'text-anchor': 'center',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
            },
        },
        {
            id: 'main_labels_orga',
            minzoom: 17,
            zindex: 109,
            type: 'symbol',
            source: 'site_plan',
            'source-layer': 'poi_point',
            filter: ['all', ['==', ['get', 'type'], 'orga'], moreLayers],
            layout: {
                'text-field': translated_text,
                'text-font': ['Questrial Regular'],
                'text-size': ['interpolate', ['linear'], ['zoom'], 17, 12, 20, 20],
                'text-anchor': 'center',
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': colours['text-halo'],
                'text-halo-width': 2,
            },
        },
        {
            id: 'main_streets_labels',
            zindex: 109,
            type: 'symbol',
            minzoom: 17,
            source: 'site_plan',
            'source-layer': 'streets_linestring',
            layout: {
                'symbol-placement': 'line',
                'symbol-spacing': ['interpolate', ['linear'], ['zoom'], 17, 400, 19, 600],
                'text-font': ['Questrial Regular'],
                'text-field': '{name}',
                'text-size': ['interpolate', ['linear'], ['zoom'], 17, 8, 20, 20],
                'text-offset': [0, 0.1],
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-color': [
                    'match',
                    ['get', 'surface'],
                    'grass',
                    colours['streets-inner-grass'],
                    colours['streets-inner'],
                ],
                'text-halo-width': 3,
            },
        },
        {
            id: 'main_building_labels',
            type: 'symbol',
            zindex: 109,
            source: 'site_plan',
            'source-layer': 'karte_osm_names_point',
            minzoom: 17.5,
            layout: {
                'text-font': ['Questrial Regular'],
                'text-field': lang == 'de' ? '{text}' : '{text_en}',
                'text-size': ['interpolate', ['linear'], ['zoom'], 17, 9, 19, 18],
                'text-anchor': 'top-left',
                'text-allow-overlap': false,
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-width': 2,
                'text-halo-color': colours['text-halo'],
                'text-halo-blur': 0.5,
            },
        },
        {
            id: 'villages_labels',
            type: 'symbol',
            filter: ['!', ['get', 'cluster']],
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'villages_point_point',
            minzoom: 18,
            layout: {
                'text-font': ['Questrial Regular'],
                'text-field': '{name}',
                'text-size': ['interpolate', ['linear'], ['zoom'], 18, 10, 19, 15],
                'text-anchor': 'center',
                'text-allow-overlap': false,
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-width': 2,
                'text-halo-color': colours['text-halo'],
                'text-halo-blur': 0.5,
            },
        },
        {
            id: 'villages_labels_major',
            type: 'symbol',
            filter: ['get', 'cluster'],
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'villages_point_point',
            minzoom: 17,
            maxzoom: 19.5,
            layout: {
                'text-font': ['Questrial Regular'],
                'text-field': '{name}',
                'text-size': ['interpolate', ['linear'], ['zoom'], 17, 14, 19, 20],
                'text-anchor': 'center',
                'text-allow-overlap': false,
            },
            paint: {
                'text-color': colours['text'],
                'text-halo-width': 2,
                'text-halo-color': colours['text-halo'],
                'text-halo-blur': 0.5,
            },
        },
        {
            id: 'woodpecker_labels',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'c23_woodpecker_point',
            minzoom: 17,
            layout: {
                'text-font': ['Questrial Regular'],
                'text-field': '{text}',
                'text-size': ['interpolate', ['linear'], ['zoom'], 16, 9, 19, 15],
                'text-anchor': 'top-left',
                'text-allow-overlap': false,
            },
            paint: {
                'text-color': 'hsl(19.3,60%,30%)',
                'text-halo-width': 2,
                'text-halo-color': colours['text-halo'],
                'text-halo-blur': 0.5,
            },
        },
        {
            id: 'main_bars_icon',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 16,
            maxzoom: 20,
            filter: ['==', ['get', 'type'], 'bar'],
            layout: {
                'icon-image': 'bar',
            },
        },
        {
            id: 'main_food_stalls_icon',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 16,
            maxzoom: 20,
            filter: ['==', ['get', 'type'], 'stalls'],
            layout: {
                'icon-image': 'food_stall',
                'icon-size': ['interpolate', ['linear'], ['zoom'], 16, 0.15, 20, 0.8],
            },
        },
        {
            id: 'main_campervans_icon',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 13,
            maxzoom: 20,
            filter: ['==', ['get', 'type'], 'campervans'],
            layout: {
                'icon-image': 'caravan',
                'icon-size': ['interpolate', ['linear'], ['zoom'], 13, 0.2, 18, 1],
                'icon-allow-overlap': true,
            },
        },
        {
            id: 'main_parking_icon',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 13,
            maxzoom: 20,
            filter: ['==', ['get', 'type'], 'parking'],
            layout: {
                'icon-image': 'parking',
                'icon-size': ['interpolate', ['linear'], ['zoom'], 13, 0.3, 18, 1],
                'icon-allow-overlap': true,
            },
        },
        {
            id: 'main_toilets_icon',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 16,
            maxzoom: 20,
            filter: ['==', ['get', 'type'], 'toilets'],
            layout: {
                'icon-image': 'toilet',
                'icon-allow-overlap': true,
            },
        },
        {
            id: 'main_accessible_toilets_icon',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 17,
            maxzoom: 20,
            filter: ['==', ['get', 'type'], 'accessible_toilet'],
            layout: {
                'icon-image': 'accessible_toilet',
                'icon-size': ['interpolate', ['linear'], ['zoom'], 17, 0.05, 22, 0.8],
                'icon-allow-overlap': true,
            },
        },
        {
            id: 'main_showers_icon',
            type: 'symbol',
            zindex: 110,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 16,
            maxzoom: 20,
            filter: ['==', ['get', 'type'], 'showers'],
            layout: {
                'icon-image': 'shower',
                'icon-allow-overlap': true,
            },
        },
        {
            id: 'bottles_icon',
            type: 'symbol',
            zindex: 115,
            source: 'site_plan',
            'source-layer': 'poi_point',
            minzoom: 15,
            maxzoom: 22,
            filter: ['==', ['get', 'type'], 'bottle_drop'],
            layout: {
                'icon-image': 'bottle',
                'icon-allow-overlap': true,
                'icon-size': ['interpolate', ['linear'], ['zoom'], 15, 0.3, 20, 0.9],
                'icon-offset': [0, -20],
            },
        },
        {
            id: 'main_icon',
            type: 'symbol',
            zindex: 120,
            source: 'camp_centroid',
            maxzoom: 13,
            layout: {
                'icon-image': 'mildenberg',
                'icon-size': ['interpolate', ['linear'], ['zoom'], 8, 0.3, 13, 0.9],
                'icon-overlap': 'always',
            },
            paint: {
                'icon-opacity': ['interpolate', ['linear'], ['zoom'], 12, 1, 13, 0],
            },
        },
    ]
}
