import { ColourScheme } from './types'

export const colours: ColourScheme = {
    background: 'hsl(100, 20%, 95%)',
    'camp-background': 'hsl(100, 20%, 95%)',
    // Base style
    'landcover-wood': 'hsl(86, 47%, 70%)',
    'landcover-wood-outline': 'hsl(86, 47%, 40%)',
    'landcover-grass': 'hsl(90, 70%, 95%)',
    'landcover-farmland': 'hsl(80, 70%, 95%)',
    'landuse-residential': 'hsl(60, 2%, 92%)',
    building: 'hsl(60, 10%, 85%)',
    'building-outline': 'hsl(60, 10%, 65%)',
    'highway-minor': 'hsl(0, 0%, 88%)',
    'highway-major-casing': 'hsl(0, 0%, 83.5%)',
    'highway-major-inner': 'hsl(0, 0%, 100%)',
    'motorway-casing': 'rgb(213, 213, 213)',
    'motorway-inner': 'rgb(234, 234, 234)',
    waterway: 'hsl(197, 55%, 70%)',
    water: 'hsl(197, 55%, 75%)',
    'water-outline': 'hsla(232, 60%, 40%, 0.3)',
    // Main map
    concrete: 'hsl(0, 0%, 86.3%)',
    'village-camping-areas': 'hsla(150, 70%, 86%, 0.4)',
    'free-camping-areas': 'hsla(110, 80%, 75%, 0.4)',
    'parking-areas': 'hsla(120, 5%, 75%, 0.4)',
    'streets-case': 'hsl(0, 0%, 73.3%)',
    'streets-inner': 'hsl(0, 0%, 90%)',
    'streets-inner-grass': 'hsl(120, 50%, 90%)',
    rails: 'hsl(201.5, 34.2%, 69.6%)',
    'rails-active': 'hsla(352.4, 45.7%, 66.1%, 0.71)',
    walls: 'rgba(86, 75, 30, 1)',
    'camp-buildings-outline': 'hsla(0, 25.5%, 40%, 1)',
    'buildings-roof': 'hsla(0, 25.5%, 60%, 1)',
    'structures-fill': 'hsl(23, 70%, 76%)',
    'structures-outline': 'hsl(23, 50%, 45%)',
    text: 'hsl(0, 0%, 13.3%)',
    'text-halo': 'hsla(0, 0%, 100%, 0.93)',
    fence: 'hsl(0, 30%, 40%)',
    toilets: 'hsl(188, 42%, 50%)',
}

export const darkColours: ColourScheme = {
    ...colours,
    background: 'hsl(100, 20%, 5%)',
    'camp-background': 'hsl(100, 20%, 5%)',
    // Base style
    'landcover-wood': 'hsl(86, 47%, 20%)',
    'landcover-wood-outline': 'hsl(86, 47%, 10%)',
    'landcover-grass': 'hsl(90, 70%, 40%)',
    'landcover-farmland': 'hsl(80, 70%, 20%)',
    'landuse-residential': 'hsl(60, 2%, 25%)',
    building: 'hsl(60, 10%, 35%)',
    'building-outline': 'hsl(60, 10%, 45%)',
    'highway-minor': 'hsl(0, 0%, 55%)',
    'highway-major-casing': 'hsl(0, 0%, 55%)',
    'highway-major-inner': 'hsl(0, 0%, 70%)',
    waterway: 'hsl(197, 60%, 20%)',
    water: 'hsl(197, 60%, 20%)',
    'water-outline': 'hsla(232, 60%, 40%, 0.3)',
    // Main map
    concrete: 'hsl(0, 0%, 40%)',
    'village-camping-areas': 'hsla(120, 10%, 30%, 0.4)',
    'parking-areas': 'hsla(120, 5%, 55%, 1)',
    'streets-case': 'hsl(0, 0%, 45%)',
    'streets-inner': 'hsl(0, 0%, 30%)',
    'streets-inner-grass': 'hsl(120, 80%, 30%)',
    rails: 'hsl(201.5, 40%, 40%)',
    'rails-active': 'hsla(352.4, 43%, 50%, 0.71)',
    walls: 'rgba(86, 75, 30, 1)',
    'buildings-roof': 'hsla(0, 40%, 30%, 1)',
    'camp-buildings-outline': 'hsla(0, 40%, 43.1%)',
    'structures-fill': 'hsl(23, 55%, 45%)',
    'structures-outline': 'hsl(23, 50%, 20%)',
    text: 'hsl(0, 0%, 97%)',
    'text-halo': 'hsla(0, 0%, 20%, 0.93)',
    fence: 'hsl(0, 30%, 40%)',
    toilets: 'hsl(188, 42%, 50%)',
}
