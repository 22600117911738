import { LayerSpecificationWithZIndex } from './types'

const style: Array<LayerSpecificationWithZIndex> = [
    {
        id: 'grid_lines',
        type: 'line',
        zindex: 1000,
        source: 'site_plan',
        'source-layer': 'karte_gitter_linien_linestring',
        paint: {
            'line-color': 'rgba(0, 0, 0, 0.2)',
        },
    },
    {
        id: 'grid_text_outer',
        type: 'symbol',
        zindex: 1000,
        minzoom: 13,
        source: 'site_plan',
        'source-layer': 'karte_gitter_koordinaten_aus_point',
        layout: {
            'text-font': ['Questrial Regular'],
            'text-field': '{text}',
            'icon-rotate': 0,
            'text-rotate': -1,
            'text-anchor': 'bottom-left',
        },
        paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.76)',
            'text-halo-width': 1,
        },
    },
    {
        id: 'grid_text_inner',
        type: 'symbol',
        zindex: 1000,
        minzoom: 13,
        source: 'site_plan',
        'source-layer': 'karte_gitter_koordinaten_innen_point',
        layout: {
            'text-font': ['Questrial Regular'],
            'text-field': '{text}',
            'icon-rotate': 0,
            'text-rotate': -1,
            'text-anchor': 'bottom-left',
        },
        paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.76)',
            'text-halo-width': 1,
        },
    },
]

export default style
