import './index.css'

import maplibregl from 'maplibre-gl'
// @ts-ignore: virtual module from vite-pwa
import { registerSW } from 'virtual:pwa-register'
import generateStyle from './style'
import Marker from './marker'
import LayerSwitcher from '@russss/maplibregl-layer-switcher'
import URLHash from '@russss/maplibregl-layer-switcher/urlhash'
import Search from './search/search'
import DistanceMeasure from './distancemeasure'
import GridControl from './grid'
import InstallControl from './installcontrol'
import ContextMenu from './contextmenu'
//import ExportControl from './export'
import { roundPosition } from './util'
import { initC3TOC } from './c3toc'
import { initNocClickable } from './noc'
import { el, mount } from 'redom'
import * as pmtiles from 'pmtiles'

// Disable live during-event layers
const postEvent = true

function isWebglSupported() {
    if (window.WebGLRenderingContext) {
        const canvas = document.createElement('canvas')
        try {
            const context = canvas.getContext('webgl2') || canvas.getContext('webgl')
            if (context && typeof context.getParameter == 'function') {
                return true
            }
        } catch (e) {
            // WebGL is supported, but disabled
        }
        return false
    }
    // WebGL not supported
    return false
}

class EventMap {
    layers: Record<string, string> = {
        'OpenStreetMap Base': 'base_',
        'Aerial Imagery': 'ortho',
        Elevation: 'hillshade',
        'Camp Background': 'background_',
        Main: 'main_',
        Villages: 'villages_',
        Grid: 'grid_',
        Power: 'power_',
        Network: 'noc_',
        'Bottle Drop': 'bottles_',
        Gates: 'gates_',
        Art: 'art_',
    }
    hiddenLayers: Record<string, string> = {
        'NOC Private': 'nocprivate_',
        Woodpecker: 'woodpecker_',
        Trash: 'trash_',
        Sanitation: 'sanitation_',
    }
    moreLayers: boolean = localStorage.getItem('showmorelayers') == 'yes'
    colourTheme: string = 'light'
    map?: maplibregl.Map
    marker?: Marker
    layer_switcher?: LayerSwitcher
    url_hash?: URLHash

    getStyle(): maplibregl.StyleSpecification {
        let hostname = 'https://map.events.ccc.de'
        if (import.meta.env.DEV) {
            hostname = 'http://localhost:8000'
        }
        const style = generateStyle('full', hostname, import.meta.env.BASE_URL, this.colourTheme)

        if (this.colourTheme == 'dark') {
            this.layer_switcher._default_visible = this.layer_switcher._default_visible.filter(
                (name: string) => name != 'Elevation'
            )
            this.layer_switcher._visible = this.layer_switcher._visible.filter(
                (name: string) => name != 'Elevation'
            )
        }

        this.layer_switcher.setInitialVisibility(style)

        if (!this.moreLayers) {
            for (const layer of style.layers) {
                for (const prefix of Object.values(this.hiddenLayers)) {
                    if (layer.id.startsWith(prefix)) {
                        if (!layer.layout) layer.layout = {}
                        layer.layout.visibility = 'none'
                    }
                }
            }
        }
        return style
    }

    updateStyle() {
        this.map!.setStyle(this.getStyle())
        this.layer_switcher._updateVisibility()
    }

    init() {
        registerSW({ immediate: true })

        if (!isWebglSupported()) {
            const error = el(
                'div#webgl-error',
                el('p', "Your browser doesn't have support for WebGL, which is required to view this map."),
                el(
                    'p',
                    'Unless your browser is very old, this is probably because WebGL is  disabled for anti-fingerprinting reasons.'
                )
            )
            mount(document.body, error)
            return
        }

        const enabled_layers = ['OpenStreetMap Base', 'Camp Background', 'Elevation', 'Main', 'Villages']

        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.colourTheme = 'dark'
        }

        if (this.moreLayers) {
            this.layers = Object.assign(this.layers, this.hiddenLayers)
            enabled_layers.push('Villages')
        }

        this.layer_switcher = new LayerSwitcher(this.layers, enabled_layers)
        this.url_hash = new URLHash(this.layer_switcher)
        this.layer_switcher.urlhash = this.url_hash
        this.marker = new Marker(this.url_hash)

        if (postEvent) {
            const protocol = new pmtiles.Protocol()
            maplibregl.addProtocol('pmtiles', protocol.tile)
        }

        this.map = new maplibregl.Map(
            this.url_hash.init({
                container: 'map',
                style: this.getStyle(),
                pitchWithRotate: false,
                dragRotate: false,
                maxBounds: [
                    [11.4484, 51.4582],
                    [14.7709, 53.6251],
                ],
                customAttribution:
                    'Base map from <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>, &copy; OpenMapTiles.' +
                    ' Powered by <a href="https://github.com/emfcamp/buildmap" target="_blank">buildmap</a>. ' +
                    '<a href="https://legal.cccv.de/" target="_blank">Impressum/Datenschutz</a>',
            })
        )
        this.map.touchZoomRotate.disableRotation()

        fetch('search/search.json')
            .then((response) => response.json())
            .then((data) => {
                if (!this.moreLayers) {
                    data = data.filter((item: any) => item.type != 'orga')
                }
                this.map!.addControl(new Search(data), 'top-left')
            })

        this.map.addControl(new InstallControl(), 'top-left')
        this.map.addControl(
            new maplibregl.NavigationControl({
                showCompass: false,
            }),
            'top-right'
        )

        this.map.addControl(
            new maplibregl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true,
                },
                trackUserLocation: true,
            })
        )

        this.map.addControl(
            new maplibregl.ScaleControl({
                maxWidth: 200,
                unit: 'metric',
            })
        )

        this.map.addControl(new DistanceMeasure(), 'top-right')
        this.map.addControl(this.marker, 'top-right')
        this.map.addControl(this.layer_switcher, 'top-right')
        //this.map.addControl(new ExportControl(), 'top-right')
        this.url_hash.enable(this.map)

        this.map.addControl(new GridControl(), 'bottom-right')

        const contextMenu = new ContextMenu(this.map)
        contextMenu.addItem('Set marker', (_e, coords) => {
            this.marker!.setLocation(coords)
        })
        contextMenu.addItem(
            'Clear marker',
            () => {
                this.marker!.setLocation(null)
            },
            () => this.marker!.location != null
        )

        contextMenu.addItem('Copy coordinates', (_e, coords) => {
            const [lng, lat] = roundPosition([coords.lng, coords.lat], this.map!.getZoom())
            navigator.clipboard.writeText(lat + ', ' + lng)
        })

        let noc_clicks = 0
        this.map.on('click', 'main_structures', (e) => {
            if (!e.features) return

            if (e.features[0].properties.entityhandle == '4044043') {
                noc_clicks += 1
                if (noc_clicks == 5) {
                    localStorage.setItem('showmorelayers', 'yes')
                    location.reload()
                }
            }
        })

        if (!postEvent) {
            initC3TOC(this.map)

            if (this.moreLayers) {
                initNocClickable(this.map, ['noc_switch', 'noc_dk'])
            }
        }

        //initVillageClickable(this.map, ['villages_labels', 'villages_labels_major'])

        if (window.matchMedia) {
            window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
                this.colourTheme = event.matches ? 'dark' : 'light'
                this.updateStyle()
            })
        }
    }
}

const em = new EventMap()
window.em = em

if (document.readyState != 'loading') {
    em.init()
} else {
    document.addEventListener('DOMContentLoaded', em.init)
}
