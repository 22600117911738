import proj4 from 'proj4'

proj4.defs([
    [
        'CCCAMP2019',
        '+proj=tmerc +ellps=GRS80 +lat_0=0 +lon_0=15 +k=0.9996 +x_0=114350 +y_0=-5877600 +units=m +no_defs',
    ],
])

type CoordsLike = [number, number] | maplibregl.LngLat

class CCCampProjection {
    convertTo(coords: CoordsLike): [number, number] {
        if ('lng' in coords) {
            coords = [coords.lng, coords.lat]
        }
        const out = proj4('WGS84', 'CCCAMP2019', coords)
        return [out[0], -out[1]]
    }

    convertToGrid(coords: CoordsLike): string | null {
        const projected = this.convertTo(coords)
        const gridCoords = [Math.ceil(projected[0] / 50), Math.ceil(projected[1] / 50)]

        if (gridCoords[0] > 26 || gridCoords[0] < 1 || gridCoords[1] > 41 || gridCoords[1] < 1) {
            return null
        }

        return String.fromCharCode(64 + gridCoords[0]) + gridCoords[1]
    }
}

export default CCCampProjection
